"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transactionTypeFilter = transactionTypeFilter;
exports.transferTypeFilter = transferTypeFilter;
//財務過濾器
/**
 * 資金流水 交易類型
 */
function transactionTypeFilter(status) {
  var statusMap = {
    pay_order: '訂單支付',
    refund_order: '訂單退款'
  };
  return statusMap[status];
}

/**
 * 轉賬類型
 */
function transferTypeFilter(status) {
  var statusMap = {
    bank: '銀行卡',
    alipay: '支付寶',
    wechat: '微信',
    cnbank: '中銀支付'
  };
  return statusMap[status];
}